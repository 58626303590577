import React from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import ColumnConfigurationDialog from 'components/bng/pages/bigTable/dialogs/ColumnConfigurationDialog';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import BngDraggable from 'components/bng/ui/BngDraggable';
import BngInputSpinner from 'components/bng/form/BngInputSpinner';
import Icon from 'components/ui/common/Icon';
import { iconForDimensionType } from 'components/bng/pages/bigTable/functions';
import Api from 'components/Api';

const CACHE = window.__BngBigTableFormCache;

const extractDimensionName = (dim) => {
  if (dim) {
    const idx = dim.indexOf('.');
    if (idx !== -1) {
      dim = dim.slice(0, idx);
    }
  }
  return dim;
};

class ColumnsTab extends React.Component {
  state = {
    showConfigModal: null,
    globalFilters: [],
    cube: null,
  };

  async componentDidMount() {
    const projectId = this.props.context.project.id;
    const globalFilters = await Api.MdxGlobalFilter.findAll(projectId, true);
    const cacheKey = `sourceCubes:${this.props.datasourceName}`;
    if (!CACHE.hasOwnProperty(cacheKey)) {
      CACHE[cacheKey] = await Api.Project.sourceCubes({
        projectId: projectId,
        sourceName: this.props.datasourceName,
      });
    }
    this.setState({
      globalFilters,
      cube: CACHE[cacheKey],
    });
  }

  getPaginationOptions() {
    return [
      { value: 'none', label: this.props.context.msg.t('pagination.none') },
      { value: 'auto', label: this.props.context.msg.t('pagination.auto') },
      { value: 'fixed', label: this.props.context.msg.t('pagination.fixed') },
    ];
  }

  onGridConfigChange(field, value) {
    let next = {
      ...this.props.gridConfig,
      [field]: value,
    };
    this.props.onGridConfigChange(next);
  }

  findFiltersForDim = (dim) => {
    if (!dim) {
      return [];
    }
    dim = extractDimensionName(dim);
    return this.state.globalFilters.filter((globalFilter) =>
      globalFilter.mdxFilters.some(
        (mf) =>
          mf.datasource === this.props.datasourceName &&
          mf.cube === this.state.cube[0].value &&
          (mf.dimension === dim || mf.dimension === `BIMF${dim}`)
      )
    );
  };

  render() {
    const {
      columns,
      calculatedColumns,
      gridConfig,
      measureColumns,
      onColumnsOrderChange,
      onColumnChange,
      onResetColumn,
      toggleColumn,
      buildDefaultColumnConfig,
    } = this.props;

    const columnsItems = columns.map((col, index) => {
      const isCalculatedColumn = calculatedColumns.some((cc) => cc.key === col.key);
      const noRefCol = isCalculatedColumn && !columns.some((column) => column.key === col.calculationProps.measure);

      return (
        <div key={col.key} className="BngBigTable__fieldList__field">
          <Icon
            icon={isCalculatedColumn ? 'functions' : iconForDimensionType(col.fieldType)}
            className="structure-icon"
          />
          <span>{col.title}</span>
          <div className="BngBigTable__fieldList__field--container">
            {noRefCol && (
              <div>
                <i
                  className={'material-icons BngBigTable__dataform-icon'}
                  title={this.props.context.msg.t('no.reference.column')}
                  style={{ color: '#F98900', marginRight: '10px' }}
                >
                  {'warning'}
                </i>
              </div>
            )}
            <BngCheckbox field={{ value: col.showColumn }} onChange={() => toggleColumn(index)} />
            <div>
              <i
                className={`material-icons BngBigTable__dataform-icon ${col.customized ? 'active' : ''}`}
                onClick={() => this.setState({ showConfigModal: { column: columns[index], idx: index } })}
                style={{ marginLeft: '0' }}
              >
                {'settings'}
              </i>
            </div>
          </div>
        </div>
      );
    });

    const selectedColumn = columns[this.state.showConfigModal?.idx];
    const isNewColumn = !selectedColumn;
    const isCalculatedColumn =
      isNewColumn || (!!selectedColumn && calculatedColumns.some((cc) => cc.key === selectedColumn.key));

    return (
      <>
        <AccordionList className="BngBigTable__menuTab ObjectRightMenuAccordion">
          <Accordion title={this.props.context.msg.t('columns')} startOpen={true}>
            <AccordionList>
              <div className="BngBigTable__ColumnsMenu BngBigTable__fieldList" style={{ margin: 10 }}>
                <BngDraggable items={columnsItems} onChangeOrder={(columns, change) => onColumnsOrderChange(change)} />
                  <div
                    className="button-menu-slide-types"
                    style={{
                      backgroundColor: '#fff',
                      color: '#005DFF',
                      left: '11px',
                      padding: '3px',
                      width: '312px'
                    }}
                    onClick={() => {
                      const column = buildDefaultColumnConfig({
                        fieldType: 'NUMERIC'
                      });
                      this.setState({ showConfigModal: { column } });
                    }}
                  >
                    <Icon icon="add_circle" style={{ marginLeft: '32px' }} />
                    <label className="label-add-link">{this.props.context.msg.t('create.calculation.column')}</label>
                  </div>
              </div>
            </AccordionList>
          </Accordion>

          <Accordion className="mt-4" title={this.props.context.msg.t('pagination')} startOpen={false}>
            <div style={{ margin: 10 }}>
              <label className="control-label">{this.props.context.msg.t('pagination')}</label>
              <BngSelectSearch
                clearable={false}
                options={this.getPaginationOptions()}
                form={{
                  setFieldValue: (name, t) => this.onGridConfigChange('pagination', t),
                }}
                field={{ value: gridConfig.pagination, onChange: _.noop }}
              />

              {gridConfig.pagination === 'fixed' && (
                <div className="mt-4">
                  <label className="control-label">{this.props.context.msg.t('page_size')}</label>
                  <BngInputSpinner
                    min={10}
                    max={10000}
                    step={10}
                    allowNegative={false}
                    value={gridConfig.pageSize}
                    onChange={(val) => this.onGridConfigChange('pageSize', val)}
                  />
                </div>
              )}
            </div>
          </Accordion>
        </AccordionList>

        {this.state.showConfigModal?.column && (
          <ColumnConfigurationDialog
            open={true}
            column={isNewColumn ? this.state.showConfigModal?.column : selectedColumn}
            columns={columns}
            calculatedColumns={calculatedColumns}
            measureColumns={measureColumns}
            onReset={() => {
              if (this.state.showConfigModal.idx >= 0) {
                onResetColumn(this.state.showConfigModal.idx, isCalculatedColumn);
              }
              this.setState({ showConfigModal: null });
            }}
            onClose={() => this.setState({ showConfigModal: null })}
            onChange={(data) => {
              if (isNewColumn) {
                this.setState({ showConfigModal: { ...this.state.showConfigModal, column: data } });
              } else {
                onColumnChange(this.state.showConfigModal.idx, data);
              }
            }}
            globalFilters={isCalculatedColumn ? null : this.findFiltersForDim(selectedColumn.key)}
            onColumnSave={this.props.onColumnSave}
            setOpenAccordion={this.props.setOpenAccordion}
            loadData={this.props.loadData}
            isCalculatedColumn={isCalculatedColumn}
            isNewColumn={isNewColumn}
          />
        )}
      </>
    );
  }
}

export default ColumnsTab;
