import './TabSet.css';

import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/ui/common/Icon';

export class Tab extends Component {
  static propTypes = {
    className: PropTypes.string,
    tabClassName: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    contentFullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    containError: PropTypes.bool,
    style: PropTypes.object,
    showNewIcon: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    tabClassName: '',
    label: '',
    icon: null,
    contentFullWidth: false,
    disabled: false,
    title: '',
    error: false,
    style: {},
    showNewIcon: false
  };

  state = {};

  render() {
    return (
      <div className={`${this.props.className} ${this.props.contentFullWidth ? 'content-full-width' : ''}`} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

const TabContext = createContext({ changeTab: _.noop });

export function useTabContext() {
  return useContext(TabContext);
}

export class TabSet extends Component {
  static propTypes = {
    buttons: PropTypes.array,
    bodyRadius: PropTypes.bool,
    internal: PropTypes.bool,
    vertical: PropTypes.bool,
    beforeChange: PropTypes.func,
    handleTabChange: PropTypes.func,
    tabToOpenIndex: PropTypes.number,
    tabs: PropTypes.array,
    selectedTab: PropTypes.number,
  };

  static defaultProps = {
    buttons: [],
    bodyRadius: true,
    internal: false,
    className: '',
    vertical: false,
    beforeChange: () => Promise.resolve(true),
    handleTabChange: _.noop,
    tabToOpenIndex: 0,
    tabs: null,
    selectedTab: null,
  };

  state = {
    selectedTab: this.props.tabToOpenIndex || 0,
  };

  changeTab = async (event, selectedTab) => {
    event?.preventDefault();
    if (await this.props.beforeChange({ current: this.state.selectedTab, next: selectedTab })) {
      this.setState({ selectedTab });
      this.props.handleTabChange(selectedTab);
    }
  };

  findTabs = () => {
    if (this.props.tabs) {
      return this.props.tabs;
    }

    return React.Children.toArray(this.props.children)
      .filter((c) => c.type === Tab)
      .map((tab) => ({ ...tab.props, render: () => tab }));
  };

  findSelectedTab = () => {
    if (Number.isFinite(this.props.selectedTab)) {
      return this.props.selectedTab;
    }
    return this.state.selectedTab;
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const tabs = this.findTabs();
    const selectedTab = this.findSelectedTab();
    if (tabs[selectedTab].disabled) {
      const tabIdx = tabs.findIndex((t) => (t.disabled ?? false) === false);
      await this.changeTab(null, tabIdx);
    }
  }

  render() {
    const { buttons, bodyRadius, internal, className, vertical } = this.props;

    const selectedTab = this.findSelectedTab();
    const tabs = this.findTabs();

    const tab = tabs[selectedTab];
    return (
      <TabContext.Provider value={{ changeTab: this.changeTab }}>
        <div className={`tabbable ${vertical ? 'tabs-left' : ''} ${className} ${internal ? 'internal-tab' : ''}`}>
          <ul className="navigation-tabs">
            {tabs.map((tab, idx) => {
              return (
                <li
                  key={tab.key ?? tab.id ?? idx}
                  className={`navigation-tab-link ${tab.tabClassName ?? ''} ${
                    idx === selectedTab ? 'active' : 'link'
                  } ${tab.disabled ? 'disabled' : ''}`}
                >
                  <a
                    className={`${tab.containError ? 'tabTitleError' : ''}`}
                    href="#"
                    onClick={(e) =>
                      tab.disabled || (tab.shouldChangeTab && !tab.shouldChangeTab())
                        ? e.preventDefault()
                        : this.changeTab(e, idx)
                    }
                    title={tab.title}
                    style={{ userSelect: 'none' }}
                  >
                    {tab.icon && <Icon icon={tab.icon} />}
                    {tab.label}
                    {tab.showNewIcon && (
                        <Icon
                            icon="new_releases"
                            style={{
                              color: '#F98900',
                              marginLeft: '5px',
                              fontSize: '1.2em'
                            }}
                        />
                    )}
                  </a>
                  {tab?.props?.alert && <Icon className="tabset-alert-icon" icon="error" />}
                </li>
              );
            })}
            <li className="tab-buttons">
              {buttons.map((button, idx) => (
                <span key={idx} className="button-container">
                  {button}
                </span>
              ))}
            </li>
          </ul>
          <div className={`tab-content ${bodyRadius ? 'bordered' : ''}`}>{tab.render?.() ?? '-'}</div>
        </div>
      </TabContext.Provider>
    );
  }
}
