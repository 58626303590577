import styles from './CreateUpfrontPaymentDialog.module.css';

import React, { useMemo, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import useTranslation from 'components/hooks/useTranslation';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import UiBlocker from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import BngRadio from 'components/bng/form/BngRadio';
import BngTable from 'components/bng/ui/BngTable';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { proposalUtils } from 'components/service/bng/AccountApi';
import useAsyncEffect from 'components/hooks/useAsyncEffect';

const buildProposalsColumns = ({ toggleExpanded, toggleSelected, selectedProposals, expanded, selectAllChildren }) => {
  return [
    {
      key: 'select',
      size: 100,
      render: (row) => {
        const isAllRow = row.id === -1;
        const hasChildren = row.children;
        return (
          <div className={`${styles.actionsRow} ${isAllRow ? '' : styles.childRow}`}>
            {hasChildren && (
              <>
                <BngIconButton
                  icon={row.id in expanded ? 'expand_less' : 'expand_more'}
                  className={`expandIconButton ${row.leaf ? 'disabled' : ''}`}
                  disabled={!hasChildren}
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    toggleExpanded(row);
                  }}
                />
                <BngCheckbox
                  field={{
                    onChange: () => {
                      if (isAllRow) {
                        selectAllChildren(row, !(row.id in selectedProposals));
                      } else {
                        toggleSelected(row, !(row.id in selectedProposals));
                      }
                    },
                    value: row.id in selectedProposals,
                  }}
                  style={{
                    transform: 'scale(1.2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingLeft: '8px',
                  }}
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      key: 'label',
      render: (row) => {
        const label = row.label
          ? row.label
          : row.children?.length > 0
          ? `#${row.id}`
          : proposalUtils.translationsForProposalPricing(row)?.name;
        return <span>{label}</span>;
      },
    },
  ];
};

export default function CreateUpfrontPaymentDialog({
  closeModal = _.noop,
  accountId,
  proposals = [],
  onUpdate = _.noop,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [upfrontPaymentMonths, setUpfrontPaymentMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedProposals, setSelectedProposals] = useState({});
  const [expanded, setExpanded] = useState({});

  useAsyncEffect({
    onMount: async () => {
      try {
        setLoading(true);
        const fetchedUpfrontPaymentMonths = await Api.Account.fetchUpfrontPaymentMonths({ accountId });
        const sortedMonths = fetchedUpfrontPaymentMonths.sort((a, b) => b.months - a.months);
        setSelectedMonth(sortedMonths[0].id);
        setUpfrontPaymentMonths(sortedMonths);
      } catch (e) {
        console.error('Error on function fetchUpfrontPaymentMonths()', e);
        UiMsg.error(t('upfront.payment.month.fetch.error'));
      } finally {
        setLoading(false);
      }
    },
  });

  const toggleSelected = (row, select) => {
    if (select) {
      selectedProposals[row.id] = row;
    } else {
      delete selectedProposals[row.id];
      if ('-1' in selectedProposals && row.id !== '-1') {
        delete selectedProposals['-1'];
      }
    }
    setSelectedProposals({ ...selectedProposals });
  };

  const selectAllChildren = (row, select) => {
    toggleSelected(row, select);
    row.children.forEach((child) => {
      toggleSelected(child, select);
    });
  };

  const toggleExpanded = (row) => {
    if (row.id in expanded) {
      delete expanded[row.id];
    } else {
      expanded[row.id] = row;
    }
    setExpanded({ ...expanded });
  };

  const proposalRows = useMemo(() => {
    const mappedProposals = proposals
      .filter((proposal) => proposal.proposalUpfrontPayments.length === 0)
      .map((proposal) => {
        proposal.children = proposal.additionalPricings.concat(proposal.planPricings).concat(proposal.servicePricings);
        proposal.level = 1;
        return proposal;
      });

    const rows = [{ id: -1, label: t('all.proposals'), level: 0, children: mappedProposals }];
    selectAllChildren(rows[0], true);
    return rows;
  }, []);

  const { total, fullPricing } = useMemo(() => {
    const monthApplied = upfrontPaymentMonths.find((month) => month.id === selectedMonth);
    if (!monthApplied) {
      return 0;
    }
    const monthDiscount = monthApplied.discount / 100;
    const proposalsToSum = Object.values(selectedProposals).filter((proposal) => proposal.id !== -1);
    const fullValue = proposalsToSum.reduce((acc, proposal) => {
      return (
        acc +
        proposal.children
          .filter((child) => child.enabled)
          .reduce((acc, child) => {
            const totalAgreedReviewRate = proposal.contract.agreedContractReviewRates.reduce(
              (acc, reviewRate) => acc + child.calculatedPricing * (reviewRate.agreedReviewRate / 100),
              0
            );
            return acc + (child.calculatedPricing + totalAgreedReviewRate);
          }, 0)
      );
    }, 0);
    const currency = proposals[0]?.contract.currency.symbol;
    const totalValue = fullValue - fullValue * monthDiscount;
    return {
      total: proposalUtils.formatPricing(totalValue * monthApplied.months, currency),
      fullPricing: proposalUtils.formatPricing(fullValue * monthApplied.months, currency),
    };
  }, [selectedProposals, selectedMonth]);

  const rows = useMemo(() => {
    const buildExpandedProposalsRows = (rows) => {
      if (!rows) return [];

      for (let i = 0; i < rows.length; i++) {
        let children = [];
        let nodeItem = rows[i];
        if (nodeItem.id in expanded && nodeItem.level === rows[0].level) {
          children = buildExpandedProposalsRows(nodeItem.children);
          rows.splice(i + 1, 0, ...children);
        }
      }

      return rows;
    };

    const proposalClone = _.cloneDeep(proposalRows);
    return buildExpandedProposalsRows(proposalClone);
  }, [expanded]);

  const cols = useMemo(() => {
    return buildProposalsColumns({
      toggleExpanded,
      toggleSelected,
      selectedProposals,
      expanded,
      selectAllChildren,
    });
  }, [selectedProposals, expanded]);

  return (
    <Dialog
      className="CreateUpfrontPaymentDialog"
      title={t('upfront.payments')}
      onClose={closeModal}
      newDialogLayout={true}
    >
      <Dialog.Body className={styles.upfrontPaymentDialogBody}>
        <UiBlocker block={loading}>
          <section className={styles.monthsSection}>
            <span className={styles.titleSpan}>{t('TIME')}</span>
            <div className={styles.monthsWrapper}>
              {upfrontPaymentMonths.map((upfrontPaymentMonth, idx) => {
                const selected = selectedMonth === upfrontPaymentMonth.id;
                return (
                  <div
                    className={`${styles.upfrontPaymentMonth} ${selected ? styles.selectedRadio : ''}`}
                    key={`month-${idx}`}
                    onClick={() => setSelectedMonth(upfrontPaymentMonth.id)}
                  >
                    <BngRadio
                      name={'month'}
                      label={t('months.amount', [upfrontPaymentMonth.months])}
                      checked={selected}
                      field={{ value: selected }}
                      form={{ setFieldValue: _.noop }}
                    />
                    <span className={styles.monthDiscount}>
                      {t('discount.percent', [upfrontPaymentMonth.discount])}
                    </span>
                  </div>
                );
              })}
            </div>
          </section>
          <section className={styles.totalSection}>
            <span>{t('total')}</span>
            <div className={styles.pricingWrapper}>
              <div className={styles.fullPricing}>{fullPricing}</div>
              <div className={styles.totalPricing}>{total}</div>
            </div>
          </section>
          <section className={styles.proposalsSection}>
            <span className={styles.titleSpan}>{t('proposals')}</span>
            <BngTable
              className={styles.proposalsTable}
              cols={cols}
              rows={rows}
              hideHeader={true}
              rowHeight={34}
              rowKeyBuilder={(row, idx) => `row-${idx}-idx-${row.id}`}
            />
          </section>
        </UiBlocker>
      </Dialog.Body>
      <Dialog.Footer className={styles.upfrontPaymentDialogFooter}>
        <BngButton variant={Variant.textButton} onClick={closeModal} disabled={loading}>
          {t('cancel')}
        </BngButton>
        <BngButton
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              const upfrontPayments = Object.values(selectedProposals)
                .filter((proposal) => proposal.id !== -1)
                .map((proposal) => {
                  return {
                    upfrontPaymentMonth: { id: selectedMonth },
                    startDate: new Date(),
                    proposal: proposal,
                  };
                });
              await Api.Account.createProposalUpfrontPayment({
                accountId,
                upfrontPayments: upfrontPayments,
              });
              UiMsg.ok(t('upfront.payment.created'));
              onUpdate();
              closeModal();
            } catch (e) {
              console.error('Error on function createUpfrontPayment()', e);
              UiMsg.error(t('upfront.payment.create.error'));
              setLoading(false);
            }
          }}
        >
          {t('confirm')}
        </BngButton>
      </Dialog.Footer>
    </Dialog>
  );
}
