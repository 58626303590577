import styles from './KeyRecyclingHierarchyTab.module.css';

import React, { useMemo } from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import useTranslation from 'components/hooks/useTranslation';
import KeyFieldSelector from 'components/bng/pages/admin/structures/KeyFieldSelector';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngInput from 'components/bng/form/BngInput';
import BngSwitch from 'components/bng/form/BngSwitch';
import Icon from 'components/ui/common/Icon';
import BngButton from 'components/bng/ui/BngButton';
import Api from 'components/Api';
import { MODALS } from 'components/ui/redux/Actions';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import BngTable from 'components/bng/ui/BngTable';
import BngIconButton from 'components/bng/ui/BngIconButton';
import DimensionEditDialog from 'components/ui/in-memory/dialogs/DimensionEditDialog';
import BimQueryConfigurationMenuItem from 'components/bng/pages/admin/structures/bimQuery/menuItems/BimQueryConfigurationMenuItem';
import { BIM_QUERY_RIGHT_MENU_SAVE } from 'components/bng/pages/admin/structures/bimQuery/BimQueryRightMenu';
import bimEventBus from 'BimEventBus';
import { RIGHT_MENU_TOGGLE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import BngCheckbox from 'components/bng/form/BngCheckbox';

const dataRecyclingFieldName = (t) => [
  {
    value: 'data_load_timestamp',
    label: t('data.load.date'),
  },
];

const dataRecyclingType = (t) => [
  {
    value: 'YEAR',
    label: t('year'),
  },
  {
    value: 'MONTH',
    label: t('month'),
  },
  {
    value: 'DAY',
    label: t('day'),
  },
];

const caseOptions = (t) => [
  {
    value: 'UPPER_CASE',
    label: t('UPPER_CASE'),
  },
  {
    value: 'LOWER_CASE',
    label: t('LOWER_CASE'),
  },
  {
    value: 'CAMEL_CASE',
    label: t('CAMEL_CASE'),
  },
  {
    value: 'DEFAULT',
    label: t('DEFAULT_CASE'),
  },
];

const accentuationOptions = (t) => [
  {
    value: 'KEEP_ACCENTUATION',
    label: t('KEEP_ACCENTUATION'),
  },
  {
    value: 'REMOVE_ACCENTUATION',
    label: t('REMOVE_ACCENTUATION'),
  },
];

const partitioningPeriods = (t) => [
  {
    value: 'YEAR',
    label: t('Range.YEAR'),
  },
  {
    value: 'MONTH',
    label: t('Range.MONTH'),
  },
  {
    value: 'WEEK',
    label: t('Range.WEEK'),
  },
];

export default function KeyRecyclingHierarchyTab({ isEditing, formikProps, options = [] }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  const { values, setFieldValue, isValid } = formikProps;

  const openDimensionDialog = ({ row, idx } = {}) => {
    const dimensionName = row?.name;

    const fields = values.fieldConfigs
      .filter((c) => c.selected && c.regularField)
      .map((c) => {
        return {
          label: c.caption,
          value: c,
        };
      });

    dispatch(
      MODALS.open(DimensionEditDialog, {
        prefetchedData: {
          fields,
          dimensions: values.dimensions,
        },
        dimensionName,
        onSave: async ({ dimension, closeModal }) => {
          const dimensions = values.dimensions.slice();
          if (Number.isFinite(idx)) {
            dimensions[idx] = dimension;
          } else {
            dimensions.push(dimension);
          }
          setFieldValue('dimensions', dimensions);
          closeModal();
        },
      })
    );
  };

  const timeDimensions = useMemo(() => {
    if (values.connection === 0) return dataRecyclingFieldName(t);
    return [
      ...dataRecyclingFieldName(t),
      ...values.fieldConfigs
        .filter((field) => field.dimensionType === 'TimeDimension')
        .map((field) => ({ value: field.fieldName, label: field.caption })),
    ];
  }, [values.fieldConfigs, values.connection]);

  const stepIsValid = values.dataRecyclingEnabled ? values.dataRecyclingFieldName !== '' : true;

  return (
    <>
      <AccordionList className={`KeyRecyclingHierarchyTab ObjectRightMenuAccordion ${!isEditing ? styles.keyRecyclingAccordionList : ''}`}>
        <Accordion title={t('key.field')} startOpen={true}>
          <KeyFieldSelector
            className={`${styles.keyFieldSelector}`}
            availableFields={values.fieldConfigs ?? []}
            onUpdate={(fields = []) => {
              const clonedFields = values.fieldConfigs.slice();
              if (fields.length > 0) {
                clonedFields.forEach((cf) => (cf.key = fields.includes(cf.fieldName)));
              } else {
                clonedFields.forEach((cf) => (cf.key = false));
              }
              setFieldValue('fieldConfigs', clonedFields);
            }}
          />
        </Accordion>
        <Accordion title={t('descriptions')}>
          <div className={`${styles.descriptionsFieldNameWrapper}`}>
            {t('case')}
            <BngSelectSearch
              className={`${styles.descriptionsFieldName}`}
              options={caseOptions(t)}
              onChange={(value) => setFieldValue('descriptionsCase', value)}
              field={{ value: values.descriptionsCase, onChange: _.noop }}
            />
            {t('accentuation')}
            <BngSelectSearch
              className={`${styles.descriptionsFieldName}`}
              options={accentuationOptions(t)}
              onChange={(value) => setFieldValue('descriptionsAccentuation', value)}
              field={{ value: values.descriptionsAccentuation, onChange: _.noop }}
            />
          </div>
          <div className={`${styles.descriptionsCheckboxesWrapper}`}>
            <BngCheckbox
              label={t('apply.trim')}
              field={{
                onChange: () => setFieldValue('trim', !values.trim),
                value: values.trim,
              }}
            />
            <BngCheckbox
              label={t('apply.trim.words')}
              field={{
                onChange: () => setFieldValue('trimWords', !values.trimWords),
                value: values.trimWords,
              }}
            />
          </div>
        </Accordion>
        <Accordion
          className={styles.dataRecyclingAccordion}
          title={
            <div className={styles.dataRecyclingAccordionTitle}>
              <span>{t('data.recycling')}</span>
              <BngSwitch
                verticalTitle={false}
                checked={values.dataRecyclingEnabled}
                onChange={() => setFieldValue('dataRecyclingEnabled', !values.dataRecyclingEnabled)}
              />
            </div>
          }
          disabled={!values.dataRecyclingEnabled}
          startOpen={false}
        >
          <div className={`${styles.dataRecyclingFieldNameWrapper}`}>
            {t('field')}
            <BngSelectSearch
              className={`${styles.dataRecyclingFieldName}`}
              options={timeDimensions}
              onChange={(value) => setFieldValue('dataRecyclingFieldName', value)}
              field={{ value: values.dataRecyclingFieldName, onChange: _.noop }}
            />
          </div>
          <div className={`${styles.keepDataFromLastWrapper}`}>
            {t('keep.data.from.last')}
            <div className={`${styles.dataRecyclingPeriodWrapper}`}>
              <BngInput
                className={`${styles.dataRecyclingPeriod}`}
                type="number"
                min={1}
                max={50}
                value={values.dataRecyclingPeriod}
                onChange={(value) => setFieldValue('dataRecyclingPeriod', value.target.value)}
              />
              <BngSelectSearch
                className={`${styles.dataRecyclingType}`}
                clearable={false}
                options={dataRecyclingType(t)}
                onChange={(value) => setFieldValue('dataRecyclingType', value)}
                field={{ value: values.dataRecyclingType, onChange: _.noop }}
              />
            </div>
          </div>
        </Accordion>
        <Accordion
          className={styles.dataRecyclingAccordion}
          title={
            <div className={styles.dataPartitioningAccordionTitle}>
              <span>{t('data.partitioning')}</span>
              <BngSwitch
                verticalTitle={false}
                checked={values.dataPartitioningEnabled}
                onChange={() => setFieldValue('dataPartitioningEnabled', !values.dataPartitioningEnabled)}
              />
            </div>
          }
          disabled={!values.dataPartitioningEnabled}
          startOpen={false}
        >
          <div className={`${styles.partitioningFieldNameWrapper}`}>
            {t('field')}
            <BngSelectSearch
              className={`${styles.partitioningFieldName}`}
              options={timeDimensions}
              onChange={(value) => setFieldValue('dataPartitioningFieldName', value)}
              field={{ value: values.dataPartitioningFieldName, onChange: _.noop }}
            />
            {t('partition.range.label')}
            <BngSelectSearch
              className={`${styles.partitioningFieldName}`}
              options={partitioningPeriods(t)}
              onChange={(value) => setFieldValue('dataPartitioningRange', value)}
              field={{ value: values.dataPartitioningRange, onChange: _.noop }}
            />
          </div>
        </Accordion>
        <Accordion title={t('hierarchies')}>
          <div className={`${styles.hierarchicalDimensionsAddWrapper}`}>
            {t('hierarchical.dimensions')}
            <Icon icon="add" className={`${styles.hierarchicalDimensionsAdd}`} onClick={() => openDimensionDialog()} />
          </div>
          <div className={`${styles.hierarchicalListWrapper} ${values.dimensions.length === 0 ? 'empty-list' : ''}`}>
            {values.dimensions.length === 0 ? (
              <div className={`${styles.hierarchicalEmptyList}`}>
                <Icon
                  className={styles.infoIcon}
                  icon="info"
                  title={t('hierarchical.dimensions.hint', [Api.baseUrl()])}
                />
                <div className={`${styles.noneHierarchicalDimensionsText}`}>{t('none.hierarchical.dimensions')}</div>
                <div className={`${styles.createHierarchicalDimension}`} onClick={() => openDimensionDialog()}>
                  {t('create.hierarchical.dimension')}
                </div>
              </div>
            ) : (
              <DimensionList formikProps={formikProps} openDimensionDialog={openDimensionDialog} />
            )}
          </div>
        </Accordion>
      </AccordionList>
      {!isEditing && (
        <div className={`${styles.ButtonsWrapper}`}>
          <BngButton
            className={`${styles.GoBackButton}`}
            onClick={() =>
              bimEventBus.emit(
                RIGHT_MENU_TOGGLE_SUBMENU,
                options.find((o) => o.key === BimQueryConfigurationMenuItem.KEY)
              )
            }
          >
            {t('bim.query.connection.configuration.go.back')}
          </BngButton>

          <BngButton
            className={`${styles.NextStepButton}`}
            disabled={!stepIsValid && !isValid}
            onClick={() => bimEventBus.emit(BIM_QUERY_RIGHT_MENU_SAVE, values)}
          >
            <div className={`${styles.FinalizeButtonLabel}`}>{t('bim.query.connection.configuration.finalize')}</div>
          </BngButton>
        </div>
      )}
    </>
  );
}

function DimensionList({ formikProps, openDimensionDialog }) {
  const { t } = useTranslation();
  const { values, setFieldValue } = formikProps;

  const reorder = (startIndex, endIndex) => {
    let result = _.cloneDeep(values.dimensions);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((r, idx) => (r.idx = idx));
    setFieldValue('dimensions', result);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const fromIdx = result.source.index;
    const toIdx = result.destination.index;

    if (fromIdx === toIdx) return;
    reorder(fromIdx, toIdx);
  };

  return (
    <BngTable
      onDropHandler={onDragEnd}
      hideHeader
      cols={[
        {
          render: (row, idx, rowProps, dragProps) => (
            <div className={`${styles.draggableIcon}`} {...dragProps.dragHandleProps}>
              <Icon icon="drag_indicator" />
            </div>
          ),
          rowClassName: styles.draggableTd,
        },
        {
          label: t('name'),
          render(row, idx, rowProps, dragProps) {
            return (
              <label className={`${styles.labelStyles}`} {...dragProps.dragHandleProps}>
                {row.name}
              </label>
            );
          },
          rowClassName: styles.fieldTd,
        },
        {
          label: t('actions'),
          render(row, idx, rowProps, dragProps) {
            return (
              <>
                <BngIconButton icon="edit" onClick={() => openDimensionDialog({ row, idx })} />
                <BngIconButton
                  icon="delete_forever"
                  onClick={() => {
                    const clone = _.cloneDeep(values.dimensions);
                    clone.splice(idx, 1);
                    setFieldValue('dimensions', clone);
                  }}
                />
              </>
            );
          },
          rowClassName: styles.actionTd,
        },
      ]}
      rows={values.dimensions}
    />
  );
}
