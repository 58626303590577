import './ContainerMenu.css';
import containerStyles from './ContainerCustomStyles.module.css';

import React, { useState } from 'react';

import Icon from 'components/ui/common/Icon';
import ContainerDescription from 'components/ui/dashboard/components/ContainerDescription';
import { processMarkdown } from 'components/bng/form/markdown/MarkdownViewer';
import EmptyComponent from 'components/ui/EmptyComponent';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import ColorUtils from 'components/bng/colorPicker/ColorUtils';

export function buildContainerCustomStyles({
  customStyle,
  highlight,
  showTitle,
  dashTransparency,
  iconAlign,
  highlightBoxColor,
}) {
  let containerHeaderClassName = [];
  let height;

  switch (customStyle.size) {
    case 'SMALL': {
      containerHeaderClassName.push(containerStyles.size_SMALL);
      height = 30;
      break;
    }
    case 'LARGE': {
      containerHeaderClassName.push(containerStyles.size_LARGE);
      height = 48;
      break;
    }
    default: {
      containerHeaderClassName.push(containerStyles.size_DEFAULT);
      height = 40;
    }
  }

  if (customStyle.headerStyle) {
    containerHeaderClassName.push(containerStyles[`headerStyle_${customStyle.headerStyle}`]);
  }

  const containerBackground = !highlight ? customStyle.backgroundColor : null;
  const containerHasCustomColor =
    containerBackground &&
    containerBackground.colors.length > 0 &&
    !containerBackground?.colors[0]?.color?.includes('transparent');

  if (
    ((customStyle.headerColor && !customStyle.headerColor?.colors[0]?.color.includes('transparent')) ||
      containerHasCustomColor) &&
    !highlight
  ) {
    containerHeaderClassName.push(containerStyles.customContainerHeaderColor);
  }

  customStyle.textFormat.forEach((format) => {
    containerHeaderClassName.push(containerStyles[`ContainerTitle_${format}`]);
  });

  if (customStyle.textAlign) {
    containerHeaderClassName.push(containerStyles[`ContainerTextAlign_${customStyle.textAlign}`]);
  }

  let backgroundStyle;

  if (containerBackground?.colors.length === 1) {
    const transparencyHex = ColorUtils.transparencyToHexValue(1 - dashTransparency / 100);
    backgroundStyle = containerBackground.colors[0].color;
    if (backgroundStyle !== 'transparent') {
      backgroundStyle += transparencyHex;
    }
  } else {
    backgroundStyle =
      containerBackground &&
      ColorUtils.genTransparentGradientImg(containerBackground.colors, containerBackground.rotation, {
        forcedOpacity: 1 - dashTransparency / 100,
        addPngBackground: false,
      });
  }

  let containerClassName = '';
  if (containerHasCustomColor) {
    const isLightBackground = ColorUtils.isLightColor(containerBackground?.colors[0]?.color);
    containerClassName = isLightBackground ? containerStyles.containerLightBg : containerStyles.containerDarkBg;
  }

  let headerBackgroundStyle =
    showTitle && !highlight
      ? customStyle.headerColor?.colors.length === 1
        ? customStyle.headerColor.colors[0].color
        : ColorUtils.genTransparentGradientImg(customStyle.headerColor?.colors, customStyle.headerColor?.rotation)
      : null;

  const iconAlignClassName = iconAlign === 'LEFT' ? containerStyles.iconLeft : containerStyles.iconRight;
  const customTitleColor = !customStyle.fontColor?.includes('transparent') ? customStyle.fontColor : null;
  const fontSize = customStyle.fontSize;
  const iconSize = customStyle.fontSize > 22 ? 32 : customStyle.fontSize + 10;
  let containerLightHighlight;

  if (highlight && highlightBoxColor) {
    containerLightHighlight = ColorUtils.isLightColor(highlightBoxColor);
  }

  const headerCustomStyles = {
    headerBackgroundStyle,
    customTitleColor,
    fontSize,
    iconSize,
    iconAlignClassName,
    containerLightHighlight,
  };

  return {
    containerClassName,
    containerHeaderClassName: containerHeaderClassName.join(' '),
    customHeight: height,
    backgroundStyle,
    headerCustomStyles,
  };
}

export default function ContainerMenu({
  editMode = false,
  item = {},
  style = {},
  containerMenuComponent = EmptyComponent,
  itemsData,
  currentBreakpoint,
  customStylesClassName = '',
  customStyleProps = {},
}) {
  const [processedDescription, setProcessedDescription] = useState({ markdown: '', result: '' });

  useAsyncEffect({
    onMount: async () => {
      const description = item?.additionalProps?.description || '';
      if (description === processedDescription.markdown) {
        return;
      }

      const result = await processMarkdown(description);
      setProcessedDescription({ markdown: description, result: result });
    },
    deps: [item, editMode],
  });

  const additionalProps = item?.additionalProps;
  const LazyContainerMenuEditor = containerMenuComponent;
  const isTitleAndIconBlank =
    additionalProps?.title === '' && additionalProps?.icon?.name === '' ? 'blank-icon-and-title' : 'with-title';

  return (
    <div
      className={`container-dropdown-menu 
      ${additionalProps?.showTitle ? isTitleAndIconBlank : 'without-title'} 
      ${editMode ? 'edit-mode' : ''} 
      ${additionalProps?.showTitle ? customStylesClassName : ''}`}
      style={{ background: customStyleProps.headerBackgroundStyle }}
    >
      {additionalProps?.showTitle && (
        <>
          <div className={`container-title ${customStyleProps.iconAlignClassName}`}>
            {additionalProps.icon && (
              <Icon
                type={additionalProps.icon.type}
                icon={additionalProps.icon.name}
                style={{ fontSize: customStyleProps.iconSize, color: customStyleProps.customTitleColor }}
              />
            )}
            <span
              title={additionalProps.title}
              style={{ color: customStyleProps.customTitleColor, fontSize: customStyleProps.fontSize }}
            >
              {additionalProps.title}
            </span>
          </div>
          {additionalProps.description && (
            <div title={processedDescription.result} className="iconDescription">
              <ContainerDescription
                icon={additionalProps.icon ? additionalProps.icon.name : ''}
                titleContainer={additionalProps.title}
                description={processedDescription.result}
                buttonStyle={{
                  fontSize: customStyleProps.iconSize,
                  color: customStyleProps.customTitleColor,
                }}
              />
            </div>
          )}
        </>
      )}
      {editMode && (
        <LazyContainerMenuEditor
          style={style}
          item={item}
          currentBreakpoint={currentBreakpoint}
          itemsData={itemsData}
          buttonStyle={{ color: customStyleProps.customTitleColor }}
        />
      )}
    </div>
  );
}
