import React from 'react';
import BaseKpi, {resizeEl} from "components/ui/kpi/BaseKpi";

export class Pie extends BaseKpi {

    constructor(props) {
        super(props);
    }


    customResizeKpi = ({width, height}) => {
        const heightPercentValue = 0.5;
        const minHeightValue = 50;
        const maxWidth = width * 0.4;

        let widthCanvas = (height * (heightPercentValue)) < minHeightValue ? minHeightValue : (height * (heightPercentValue)) * 1.8;

        if ((widthCanvas > maxWidth)) {
            widthCanvas = widthCanvas * (maxWidth / widthCanvas);
        }

        const heightCanvas = widthCanvas * 0.55;

        this.props.div.style.padding = 0;

        resizeEl(this.$percentEl, {
            fontSize: {minValue: 12, multiplier: 0.12, refSize: heightCanvas},
            lineHeight: {minValue: 24, multiplier: 0.24, refSize: heightCanvas},
        });
    };

    initComponent = ({height, width}) => {
        const minHeight = 50;
        const heightPercentValue = 0.6;
        const maxRadiusHeight = 25;
        let heightDiv = (height * (heightPercentValue)) < minHeight ? minHeight : (height * (heightPercentValue));

        let maxWidth = width * 0.4;
        heightDiv = (heightDiv > maxWidth) ? maxWidth : heightDiv;

        let radius = (heightDiv / 2.2);

        if (isNaN(radius)) {
            radius = 0;
        }

        let color = this.state.color;
        let backgroundColor = '#E0E0E0';
        if (this.props.invert && this.props.percentage < 0) {
            backgroundColor = color;
            color = '#E0E0E0';
        }

        if (this.props.growthInfoRender) {
            this.$bodyEl.querySelector('.KpiGrowth').style.width = `${heightDiv * 0.9}px`;
        }

        if(this.$percentEl){
            this.$percentEl.style.width = `${heightDiv * 0.9}px`;
        }

        jQuery(this.$pieKpiEl).peity('pie', {
            fill: [color, backgroundColor],
            radius: (radius < maxRadiusHeight ? maxRadiusHeight : radius)
        });
    };

    render() {
        let percent = "0";
        if (this.props.percentage !== 'Infinity') {
            let x = parseFloat(this.props.percentage);
            percent = x + "/100";
        }
        if (this.props.invert === true && this.props.percentage < 0) {
            let x = parseFloat(this.props.percentage) * -1;
            percent = (100 - x) + "/100";
        }

        return (
            <div style={{margin: 'auto'}}>
                <div className={`KpiBody Qplot`}>
                    <div ref={el => this.$bodyEl = el}
                         style={{textAlign: 'right', marginRight: '10px', marginTop: '10px'}}
                         className="KpiInternalBody">
                        <div ref={el => this.$pieKpiEl = el} className="pie">{`${percent}`}</div>
                        <div className="kpi-percent" ref={el => this.$percentEl = el}
                             style={{textAlign: 'center', fontWeight: '500', marginLeft: 'auto'}}>
                            {this.state.percentage + "%"}
                        </div>
                        {this.useGrowth()}
                    </div>
                    {this.useRightFragment({container: this.$bodyEl})}
                </div>
                <div ref={el => this.$containerBandsEl = el}>
                    {this.useBands({container: this.$containerBandsEl})}
                </div>
            </div>
        )
    }

}

