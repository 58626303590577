import 'components/ui/publisher/PublisherListDialog.css'

import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import Button from "components/ui/Button";
import Dialog from "components/ui/Dialog";
import UiMsg from "components/ui/UiMsg";
import DropdownTable from "components/ui/publisher/DropdownTable";
import PaginateTable from "components/ui/common/PaginateTable";
import {MODALS} from "components/ui/redux/Actions";
import SelectObjectDialogContainer from "components/ui/common/SelectObjectDialogContainer";
import PublisherIncorporateLink from "components/ui/publisher/PublisherIncorporateLink";


export const publishSelectItems = async ({context, dispatch = _.noop, onPublish = _.noop, closeModal = _.noop}) => {
    let itemsAlreadyPublished = null;
    try {
        itemsAlreadyPublished = await Api.Publisher.getAllPublishedPaths();
    } catch (e) {
        console.error(e);
    }
    dispatch(MODALS.open(SelectObjectDialogContainer, {
        onSelectPath: async path => {
            try {
                await Api.Publisher.publishSelectedItems({
                    itemPath: path.itemPath,
                    title: path.title
                });
                UiMsg.ok(context.msg.t('successfully.published.objects'));
            } catch (e) {
                UiMsg.error(`${context.msg.t('error.published.objects')} : ${e}`);
            }
        },
        customSaveHandler: (props, state) => {
            closeModal();
            dispatch(MODALS.open(PublisherIncorporateLink, {
                caption: state.title[0],
                path: state.path[0],
                onUpdate: onPublish,
                closeOnSave: true
            }));
        },
        typeField: 'radio',
        blockedPaths: itemsAlreadyPublished,
        okLabel: 'create.new.publication'
    }))
};

class PublisherListDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true)
    };

    state = {
        publisher: [],
        first: 0,
        page: 1,
        maxresults: 10,
        totalpages: 1,
        totalpublisher: 0
    };

    componentDidMount() {
        this.findPublisher(0);
    }

    findPublisher = async (val) => {
        const {maxresults} = this.state;
        const first = val < 0 ? 0 : val;
        const publisheritem = await Api.Publisher.getAllPublishers(maxresults, first);
        if (!_.isEmpty(publisheritem)) {
            this.setState({
                publisher: publisheritem,
                first: first,
                totalpages: publisheritem[0].totalpages,
                totalpublisher: publisheritem[0].totalpublishers
            });
        } else {
            this.setState({first: 0, totalpages: 1, totalpublisher: 0});
        }
    }

    onDelete = () => {
        this.findPublisher(this.state.first);
    }

    createTableItem = (publisher, i) => {
        let date = moment(publisher.date).format('DD/MM/YYYY HH:mm');

        return (
            <tr className="publisherlist-table-tr" key={i} id={i}>
                <td style={{width: 270, maxWidth: 270}} className="publisherlist-table-td">
                    <div title={publisher.path} className="div-information-publisher">{publisher.path}</div>
                </td>
                <td style={{width: 270, maxWidth: 270}} className="publisherlist-table-td">
                    <div title={publisher.caption} className="div-information-publisher">{publisher.caption}</div>
                </td>
                <td style={{width: 140, maxWidth: 140}} className="publisherlist-table-td">
                    <div title={date} className="div-information-publisher">{date}</div>
                </td>
                <td style={{width: 90, maxWidth: 90}} className="publisherlist-table-td">
                    <div className="button-views">{publisher.pageViews}</div>
                </td>
                <td className="publisherlist-table-td">
                    <div><DropdownTable onDelete={this.onDelete} path={publisher.path} caption={publisher.caption} id={publisher.id}/></div>
                </td>
            </tr>
        );
    }

    handleChangeNumberPerPage = async (perPage, pageNumber) => {
        let page = Number(perPage);
        await this.setState({maxresults: page});
        return await this.findPublisher(this.state.first)
    };

    handlePagination = async (param) => {
        let first = (param - 1 < 0 ? 1 : param - 1) * this.state.maxresults;
        await this.setState({first: first, page: param});
        return await this.findPublisher(this.state.first);
    };

    openSelectObjectDialog = () => {
        const onPublish = async () => {
            await this.findPublisher(this.state.first);
        }
        publishSelectItems({...this.props, onPublish});
    };

    render() {
        return (
            <Dialog contentFullWidth={true} className="Publisher-list-dialog large" open={this.props.open}
                    title={this.props.context.msg.t('publications')}
                    onClose={this.props.closeModal}>
                <PaginateTable handleChangePage={this.handlePagination}
                               handleChangeNumberPerPage={this.handleChangeNumberPerPage} initialPass={10} height={400}
                               totalItens={this.state.totalpublisher} externalChange={false}>
                    <div style={{maxHeight: 400, minHeight: 400}} className="scroll-bar-object-select">
                        <table style={{border: 'none'}} className="table table-condensed table-border table-hover">
                            <thead>
                            <tr>
                                <th style={{paddingLeft: 14}}>{this.props.context.msg.t('path')}</th>
                                <th style={{paddingLeft: 14}}>{this.props.context.msg.t('managefiles_header_label_name')}</th>
                                <th style={{paddingLeft: 14}}>{this.props.context.msg.t('publication.date')}</th>
                                <th>{this.props.context.msg.t('visualizations')}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.publisher.map(this.createTableItem)}
                            </tbody>
                        </table>
                    </div>
                </PaginateTable>
                <div className="row-fluid">
                    <div className="span12 text-right btn-fix">
                        <Button onClick={this.props.closeModal} className="bng-button cancel">
                            {this.props.context.msg.t('cancel_button')}
                        </Button>
                        {' '}
                        <Button style={{width: 130}} className="bng-button save"
                                onClick={() => this.openSelectObjectDialog()}>
                            {this.props.context.msg.t('create.new.publication')}
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default ContextEnhancer(connect()(PublisherListDialog));