import "./SelectObjectDialog.css";

import React, {Component} from "react";
import PropTypes from "prop-types";

import Dialog from "components/ui/Dialog";
import Api from "components/Api";
import {DefaultDialogActions, Field, wrapForm} from "components/ui/FormUtils";
import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import TreeTable from "components/ui/common/TreeTable";
import SelectObjectRadio from "components/ui/common/SelectObjectRadio";
import SelectObjectsCheckbox from "components/ui/common/SelectObjectsCheckbox";

export const objectSelect = {
    object: ""
};

class SelectObjectDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        path: PropTypes.string,
        folder: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        typeField: PropTypes.any,
        byProject: PropTypes.bool,
        object: PropTypes.any,
        objectTitle: PropTypes.any,
        objectIcon: PropTypes.any,
        onSelectPath: PropTypes.func,
        fileExtension: PropTypes.string,
        viewType: PropTypes.string,
        blockedPaths: PropTypes.array,
        required: PropTypes.bool,
        dialogComponent: PropTypes.any,
        onChangeListener: PropTypes.func,
        className: PropTypes.string,
        allowClear: PropTypes.bool,
        customSaveHandler: PropTypes.func,
        customDialogProps: PropTypes.any,
        projectId: PropTypes.number,
        okLabel: PropTypes.string
    };

    static defaultProps = {
        onSubmit: () => Promise.resolve(true),
        path: '',
        folder: '',
        name: '',
        description: '',
        typeField: 'radio',
        byProject: false,
        object: [],
        objectTitle: [],
        objectIcon: [],
        onSelectPath: _.noop,
        fileExtension: null,
        viewType: null,
        blockedPaths: [],
        required: false,
        dialogComponent: undefined,
        onChangeListener: undefined,
        className: '',
        allowClear: true,
        customSaveHandler: undefined,
        customDialogProps: {},
    };

    state = {
        availableFolders: [],
        items: [],
        loading: false,
        viewFolders: [],
        objectSelect: "",
        icon: [],
        title: [],
        path: [],
        projects: [],
        pathObject: {},
    };

    constructor(props) {
        super(props);
        if (props.object) {
            if (props.typeField === 'radio') {
                this.state.path = [props.object];
                if (props.objectTitle) {
                    this.state.title = [props.objectTitle];
                }
                if (props.objectIcon) {
                    this.state.icon = [props.objectIcon];
                }
            } else if (props.object.length > 0) {
                this.state.path = props.object ?? [];
                this.state.title = props.objectTitle ?? [];
            }
        }
    }

    componentWillMount() {
        this.props.initialize({
            folder: this.props.folder,
            name: this.props.name,
            description: this.props.description,
        })
    }

    async componentDidMount() {
        this.setState({loading: true});
        try {
            let items = this.props.folders;
            if (!items) {
                items = await Api.Menu.findRoots(this.props.projectId);
            }
            const folders = this.filterBlockedItems(items);

            if (this.props.byProject) {
                const projectsAvailable = await Api.Navbar.projectsNameByUser();
                this.setState({projects: projectsAvailable});
            }

            this.setState({viewFolders: folders});
            objectSelect.object = this.props.object;

        } catch (e) {
            console.error(e);
            UiMsg.ajaxError('error', e);
        } finally {
            this.setState({loading: false});
        }
    }

    filterBlockedItems = (folders) => {
        const {blockedPaths} = this.props;
        if (!_.isEmpty(blockedPaths)) {
            let val = _.cloneDeep(folders);
            val = val.filter(row => {
                return this.returnSearchObjects(row, blockedPaths)
            })
            return val;
        }
        return folders;
    };

    returnSearchObjects = (row, blockedPaths) => {
        if (blockedPaths.includes(row.href) || blockedPaths.includes(row.path)) {
            return false;
        }

        if (row.container) {
            row.children = row.children.filter(actualRow => {
                if (actualRow.container) {
                    return this.returnSearchObjects(actualRow, blockedPaths);
                } else {
                    if (blockedPaths.includes(actualRow.href)) {
                        return false;
                    }
                }
                return true;
            });
        }
        return true;
    };

    setPathObject = (value) => {
        let icon = this.state.icon;
        let title = this.state.title;
        let path = this.state.path;
        let indexPath = path.indexOf(value.href);

        if (this.props.typeField === 'radio') {
            if (path.length > 0) {
                path = [];
                title = [];
                icon = [];
            }
            title.push(value.text);
            path.push(value.href);
            icon.push(value.icon);
        } else {
            if (indexPath >= 0) {
                path.splice(indexPath, 1);
                if (title[indexPath]) {
                    title.splice(indexPath, 1);
                }
                if (icon[indexPath]) {
                    icon.splice(indexPath, 1);
                }
            } else {
                icon.push(value.icon);
                title.push(value.text);
                path.push(value.href);
            }
        }
        this.setState({icon, title, path}, this.notifyOnChangeListener);
    };

    save = async () => {
        if(this.props.customSaveHandler) {
            this.props.customSaveHandler(this.props, this.state);
            return;
        }

        if (this.props.required && (this.state.path.length === 0 || this.state.path[0] instanceof Array)) {
            UiMsg.error(this.props.context.msg.t('required.select.an.object'));
            return;
        }

        const pathObject = {
            itemPath: this.state.path,
            icon: this.state.icon,
            title: this.state.title
        };

        let closeModal;
        if (this.props.typeField === 'radio') {
            if (pathObject.itemPath != "#" && pathObject.itemPath != "") {
                closeModal = await this.props.onSelectPath(pathObject, this.props.viewType);
            }
        } else {
            closeModal = await this.props.onSelectPath(pathObject, this.props.viewType);
        }

        if(closeModal !== false) {
            this.props.closeModal();
        }
    };

    cleanSelectionEvent = () => {
        this.setState({path: [], icon: [], title: []}, this.notifyOnChangeListener);
    };

    notifyOnChangeListener = () => {
        if(!this.props.onChangeListener) return;
        this.props.onChangeListener(this.state);
    }

    changeProjectRoots = async (event) => {
        this.setState({loading: true});
        try {
            const projectId = event.target.value;
            const roots = await Api.Menu.findRootsFor({projectId});
            this.setState({viewFolders: roots});
        } catch (e) {
            UiMsg.ajaxError('error', e);
        } finally {
            this.setState({loading: false});
        }
    };

    filterTreeObjects = ({row, fileExtension}) => {
        return (!!this.props.fileExtension && fileExtension !== this.props.fileExtension)
            || (!!this.props.viewType && !(row.viewType || []).includes(this.props.viewType))
    }

    render() {

        if (!this.props.initialized) {
            return null;
        }
        const {path, projects, viewFolders, loading} = this.state;

        const DialogComponent = this.props.dialogComponent ?? Dialog;
        const TreeTableComponent = _.isString(this.props.typeField)
            ? this.props.typeField === 'radio' ? SelectObjectRadio : SelectObjectsCheckbox
            : this.props.typeField;

        return (
            <DialogComponent className={`SelectObjectDialog large ${this.props.className ?? ''}`}
                             open={this.props.open}
                    title={this.props.context.msg.t('select.object')}
                    onClose={this.props.closeModal}
                    loading={loading}
                    contentFullWidth={true}
                    {...this.props.customDialogProps}
            >

                {this.props.byProject &&
                    <div style={{display: 'inline-flex', paddingLeft: 22}}>
                        <div className="label-select-project">{this.props.context.msg.t('project')}</div>
                        <Field name="projects"
                               onChange={this.changeProjectRoots}
                               className="select-project-folder"
                               componentClass="select">
                            {projects
                                .filter(project => project.accountId === this.props.context.project.accountId)
                                .map((project) => {
                                    return (
                                        <option key={`project-${project.id}`}
                                                value={project.id}
                                                className={project.name}>
                                            {project.name}
                                        </option>
                                    );
                                })}
                        </Field>
                    </div>
                }

                <form onSubmit={this.props.handleSubmit(this.save)}>
                    {viewFolders.length > 0 &&
                    <div className="row-fluid">
                        <div className="span12">
                            <TreeTable paddingleft={true}
                                       folders={viewFolders}
                                       additionalFilter={this.filterTreeObjects}
                                       component={TreeTableComponent}
                                       objectSelect={path}
                                       onChange={this.setPathObject}
                                       onClearSelection={this.cleanSelectionEvent}
                                       allowClear={this.props.allowClear}
                            />
                        </div>
                    </div>
                    }
                    {(!this.props.onChangeListener || !!this.props.customSaveHandler) &&
                    <>
                        <hr/>
                        <DefaultDialogActions {...this.props} />
                    </>
                    }

                </form>
            </DialogComponent>
        );
    }
}

export default wrapForm({
    component: ContextEnhancer(SelectObjectDialog),
    initialValues: {
        search: ''
    },
    name: 'selectObjectDialog'
});